* {
box-sizing: border-box;
margin:0;
padding:0;
/* font-family: "Encode Sans Expanded", sans-serif; */
font-family: 'Poppins', sans-serif;
}


ul {
    list-style: none
}

a {
    text-decoration-line:none;
    color: #6bbe6b;
}
a:hover {
    text-decoration-line:none;
    color:rgb(240, 16, 0);
}


